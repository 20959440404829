import { useState } from "react";
import Fade from "react-reveal/Fade";
import { MdArrowBackIosNew } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MeetingDetail from "../components/MeetingDetail/MeetingDetail";
import MeetingDetailWedding from "../components/MeetingDetailWedding/MeetingDetailWedding";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Swiper, SwiperSlide } from "swiper/react";
import Table from "../components/Table/Table";
import TableTwo from "../components/TableTwo/TableTwo";
import "../assets/css/MeetingEvents/MeetingEvents.css";
import Footer from "../components/Footer/Footer";
import { MdAdminPanelSettings } from "react-icons/md";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import required modules
import { FreeMode } from "swiper";
import Header from "../components/Header/Header";
function MeetingEvents(props) {
  const [isOpen, setIsOpen] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);

  const [width, setWidth] = useState(600);
  const [widthTwo, setWidthTwo] = useState(600);
  const [clicked, setClicked] = useState(false);
  const [clickedTwo, setClickedTwo] = useState(false);

  const handleClick = () => {
    if (!clicked) {
      setWidth(width + 750);
      setClicked(true);
    }
  };

  const handleReset = () => {
    setWidth(600);
    setClicked(false);
  };

  const handleClickTwo = () => {
    if (!clickedTwo) {
      setWidthTwo(widthTwo + 750);
      setClickedTwo(true);
    }
  };

  const handleResetTwo = () => {
    setWidthTwo(600);
    setClickedTwo(false);
  };

  return (
    <>
      {" "}
      <Header />
      <div className="meeting_events">
        <div className="">
          <div className="meeting_inner pb-5 d-flex justify-content-center">
            <div className="crowne-main d-flex justify-content-center">
              <div
                className="item_one_room"
                onClick={handleClick}
                style={{
                  width: `${width}px`,

                  transition: "width 1.5s ease",
                  display: clickedTwo ? "none" : "block", // conditional style
                }}
              >
                {clicked ? (
                  <div>
                    <div className="tab_header">
                      <Tabs>
                        <TabList className="tab_list">
                          <Tab className="tab_one ">
                            <span className="bold-text">
                              {" "}
                              {props.strings["text_111"]}
                            </span>{" "}
                            {props.strings["text_112"]}
                          </Tab>
                          <Tab className="tab_two">
                            <span className="bold-text">
                              {" "}
                              {props.strings["text_113"]}
                            </span>
                            {props.strings["text_136"]}
                          </Tab>
                        </TabList>{" "}
                        <TabPanel>
                          {" "}
                          <MeetingDetail />
                        </TabPanel>
                        <TabPanel>
                          {" "}
                          <MeetingDetailWedding />
                        </TabPanel>
                      </Tabs>

                      <div class="close-container-x" onClick={handleReset}>
                        <div class="leftright-x"></div>
                        <div class="rightleft-x"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="meeting_card">
                    <div className="meeting_card_inner">
                      <h4>
                        {" "}
                        <span className="bold-text">
                          {props.strings["text_111"]}
                        </span>{" "}
                        {props.strings["text_112"]}
                      </h4>
                      <p>{props.strings["text_267"]}</p>
                    </div>
                    <div className="col-lg-6 meeting_card_bg"> </div>
                  </div>
                )}
              </div>
            </div>
            <div className="crowne-main d-flex justify-content-center">
              <div
                className="item_two_room"
                onClick={handleClickTwo}
                style={{
                  width: `${widthTwo}px`,

                  transition: "width 1.5s ease",
                  display: clicked ? "none" : "block", // conditional style
                }}
              >
                {clickedTwo ? (
                  <div>
                    <div className="tab_header">
                      {" "}
                      <Tabs defaultIndex={1}>
                        <TabList className="tab_list">
                          <Tab className="tab_one ">
                            <span className="bold-text">
                              {props.strings["text_111"]}
                            </span>{" "}
                            {props.strings["text_112"]}
                          </Tab>
                          <Tab className="tab_two">
                            <span className="bold-text">
                              {props.strings["text_113"]}
                            </span>
                            {props.strings["text_136"]}
                          </Tab>
                        </TabList>{" "}
                        <TabPanel>
                          {" "}
                          <MeetingDetail />
                        </TabPanel>
                        <TabPanel>
                          {" "}
                          <MeetingDetailWedding />
                        </TabPanel>
                      </Tabs>
                      <div class="close-container-x" onClick={handleResetTwo}>
                        <div class="leftright-x"></div>
                        <div class="rightleft-x"></div>
                      </div>
                    </div>

                    <div className="room_card_standard d-flex"></div>
                  </div>
                ) : (
                  <div className="meeting_card">
                    <div className="meeting_card_inner">
                      <h4>
                        {" "}
                        <span className="bold-text">
                          {props.strings["text_113"]}
                        </span>
                        {props.strings["text_136"]}
                      </h4>
                      <p>{props.strings["text_268"]}</p>
                    </div>
                    <div className="col-lg-6 wedding_card_bg"> </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="video_section">
            <div className="video_section_inner">
              <video className="meeting_video" autoPlay={true} loop muted>
                <source src="/video/crowne_meeting.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <h2 className="table_section_title">{props.strings["text_264"]}</h2>
          <div className="table_section">
            <Table />
          </div>
          <h2 className="table_two_section_title">
            {props.strings["text_265"]}
          </h2>
          <div className="table_two_section">
            <TableTwo />
          </div>
          <div className="tecnique_equipments mt-5">
            <p>
              <strong> {props.strings["text_309"]}</strong>
            </p>
            <li> ✔ {props.strings["text_296"]}</li>
            <li> ✔ {props.strings["text_297"]}</li>
            <li> ✔ {props.strings["text_298"]}</li>
            <li> ✔ {props.strings["text_299"]}</li>
            <li> ✔ {props.strings["text_300"]}</li>
            <li> ✔ {props.strings["text_301"]}</li>
            <li> ✔ {props.strings["text_302"]}</li>
            <li> ✔ {props.strings["text_303"]}</li>
            <li> ✔ {props.strings["text_304"]}</li>
            <li> ✔ {props.strings["text_305"]}</li>
            <li> ✔ {props.strings["text_306"]}</li>
            <li> ✔ {props.strings["text_307"]}</li>
            <li className="mt-3">
              {" "}
              <strong>{props.strings["text_308"]}</strong>
            </li>
          </div>

          <div className="download_sheet mt-5">
            <div className="">
              {" "}
              <h5 className="">{props.strings["text_266"]}</h5>
              <p>
                <a
                  className="white_link"
                  href="mailto:sales@crowneplazaankara.com.tr"
                  target="_blank"
                >
                  {" "}
                  sales@crowneplazaankara.com.tr
                </a>
              </p>
              <p>
                {" "}
                <a className="white_link" href="tel:+03123200000">
                  +90 312 320 00 00
                </a>
              </p>
            </div>
            <div className="">
              {" "}
              <button className="btn pdf_button">
                {props.currentLanguageCode === "tr" ? (
                  <a href="/pdf/crowne_plaza_ankara_fact_sheet_tr.pdf" download>
                    {props.strings["text_144"]}
                  </a>
                ) : (
                  <a
                    href="/pdf/crowne_plaza_ankara_fact_sheet_eng.pdf"
                    download
                  >
                    {props.strings["text_144"]}
                  </a>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

MeetingEvents.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(MeetingEvents));
