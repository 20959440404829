import * as React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { BsWifi } from "react-icons/bs";
import "./roomDetails.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import * as mdb from "mdb-ui-kit"; // lib
import { Input } from "mdb-ui-kit"; // module

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import required modules
import { useState } from "react";

import "react-image-lightbox/style.css";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";

function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const images = [
    "/img/st_kingbed_01.webp",
    "/img/st_kingbed_02.webp",
    "/img/st_kingbed_03.webp",
    "/img/st_kingbed_04.webp",
  ];
  const imagesTwo = [
    "/img/st_two_single_01.webp",
    "/img/st_two_single_02.webp",
    "/img/st_two_single_03.webp",
    "/img/st_two_single_04.webp",
  ];
  const imagesThree = [
    "/img/st_dqueen_01.webp",
    "/img/st_dqueen_02.webp",
    "/img/st_dqueen_01.webp",
    "/img/st_dqueen_02.webp",
  ];
  const imagesFour = [
    "/img/accessible_01.webp",
    "/img/accessible_02.webp",
    "/img/accessible_03.webp",
    "/img/accessible_04.webp",
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Tabs className="room_tab_detail">
        <TabList className="tab_list">
          <Tab className="tab_one_room room_tab_pink">
            <h6 className="">{props.strings["text_324"]}</h6>
          </Tab>
          <Tab className="tab_two_room room_tab_pink">
            <h6 className="">{props.strings["text_325"]}</h6>
          </Tab>
          <Tab className="tab_three_room room_tab_pink">
            <h6 className="">{props.strings["text_326"]}</h6>
          </Tab>
          <Tab className="tab_four_room room_tab_pink">
            <h6 className="">{props.strings["text_327"]}</h6>
          </Tab>
        </TabList>
        <TabPanel>
          <div className=" room_standard">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {images.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div
                      className="carousel-indicators"
                      
                    >
                      {images.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                {" "}
                <div className="room_detay">
                  <h4 className="text-center pt-3"> {props.strings["imkan"]}</h4>
                  <div className="room_text_section">
                    <div className="col-lg-7">
                      {" "}
                      <p>
                        <ul>
                          <li>✔ {props.strings["text_187"]}</li>
                          <li>✔ {props.strings["text_188"]}</li>
                          <li>✔ {props.strings["text_189"]}</li>
                          <li>✔ {props.strings["text_190"]}</li>
                          <li>✔ {props.strings["text_191"]}</li>
                          <li>✔ {props.strings["text_281"]}</li>
                        </ul>
                      </p>
                    </div>
                    <div className="col-lg-6">
                      {" "}
                      <p>
                        <ul>
                          <li>✔ {props.strings["text_192"]}</li>
                          <li>✔ {props.strings["text_193"]}</li>
                          <li>✔ {props.strings["text_194"]}</li>
                          <li>✔ {props.strings["text_195"]}</li>
                          <li>✔ {props.strings["text_282"]}</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className=" room_twin">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {imagesTwo.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div
                      className="carousel-indicators"
                      
                    >
                      {imagesTwo.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={imagesTwo[photoIndex]}
                      nextSrc={imagesTwo[(photoIndex + 1) % imagesTwo.length]}
                      prevSrc={
                        imagesTwo[
                          (photoIndex + imagesTwo.length - 1) % imagesTwo.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + imagesTwo.length - 1) % imagesTwo.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imagesTwo.length)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-center pt-3"> {props.strings["imkan"]}</h4>
                <div className="room_text_section">
                  <div className="col-lg-7">
                    {" "}
                    <p>
                      <ul>
                        <li>✔ {props.strings["text_187"]}</li>
                        <li>✔ {props.strings["text_255"]}</li>
                        <li>✔ {props.strings["text_189"]}</li>
                        <li>✔ {props.strings["text_190"]}</li>
                        <li>✔ {props.strings["text_191"]}</li>
                        <li>✔ {props.strings["text_281"]}</li>
                      </ul>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p>
                      <ul>
                        <li>✔ {props.strings["text_192"]}</li>
                        <li>✔ {props.strings["text_193"]}</li>
                        <li>✔ {props.strings["text_194"]}</li>
                        <li>✔ {props.strings["text_195"]}</li>
                        <li>✔ {props.strings["text_282"]}</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="d-flex room_queen">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {imagesThree.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div
                      className="carousel-indicators"
                      
                    >
                      {imagesThree.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={imagesThree[photoIndex]}
                      nextSrc={
                        imagesThree[(photoIndex + 1) % imagesThree.length]
                      }
                      prevSrc={
                        imagesThree[
                          (photoIndex + imagesThree.length - 1) %
                            imagesThree.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + imagesThree.length - 1) %
                            imagesThree.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imagesThree.length)
                      }
                    />
                  )}
                </div>
              </div>{" "}
              <div className="col-lg-6">
                <h4 className="text-center pt-3"> {props.strings["imkan"]}</h4>
                <div className="room_text_section">
                  <div className="col-lg-7">
                    {" "}
                    <p>
                      <ul>
                        <li>✔ {props.strings["text_196"]}</li>
                        <li>✔ {props.strings["text_197"]}</li>
                        <li>✔ {props.strings["text_198"]}</li>
                        <li>✔ {props.strings["text_199"]}</li>
                        <li>✔ {props.strings["text_200"]}</li>
                        <li>✔ {props.strings["text_281"]}</li>
                      </ul>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p>
                      <ul>
                        <li>✔ {props.strings["text_201"]}</li>
                        <li>✔ {props.strings["text_202"]}</li>
                        <li>✔ {props.strings["text_203"]}</li>
                        <li>✔ {props.strings["text_204"]}</li>
                        <li>✔ {props.strings["text_282"]}</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="d-flex room_engelli">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-mdb-ride="carousel"
                  >
                    <div className="carousel-inner mb-5">
                      {imagesFour.map((image, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            src={image}
                            className="d-block w-100"
                            alt="..."
                          />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselExampleIndicators"
                      data-mdb-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                    <div
                      className="carousel-indicators"
                      
                    >
                      {imagesFour.map((image, index) => (
                        <button
                          key={index}
                          type="button"
                          data-mdb-target="#carouselExampleIndicators"
                          data-mdb-slide-to={index}
                          className={index === photoIndex ? "active" : ""}
                          aria-current={index === photoIndex ? "true" : "false"}
                          aria-label={`Slide ${index + 1}`}
                          style={{ width: 100 }}
                          onClick={() => openLightbox(index)}
                        >
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${index + 1}`}
                          />
                        </button>
                      ))}
                    </div>
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={imagesFour[photoIndex]}
                      nextSrc={imagesFour[(photoIndex + 1) % imagesFour.length]}
                      prevSrc={
                        imagesFour[
                          (photoIndex + imagesFour.length - 1) %
                            imagesFour.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + imagesFour.length - 1) %
                            imagesFour.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imagesFour.length)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="text-center pt-3"> {props.strings["imkan"]}</h4>
                <div className="room_text_section">
                  <div className="col-lg-7">
                    {" "}
                    <p>
                      <ul>
                        <li>✔ {props.strings["text_205"]}</li>
                        <li>✔ {props.strings["text_206"]}</li>
                        <li>✔ {props.strings["text_207"]}</li>
                        <li>✔ {props.strings["text_208"]}</li>
                        <li>✔ {props.strings["text_209"]}</li>
                        <li>✔ {props.strings["text_211"]}</li>
                        <li>✔ {props.strings["text_213"]}</li>
                        <li>✔ {props.strings["text_194"]}</li>
                        <li>✔ {props.strings["text_192"]}</li>
                      </ul>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p>
                      <ul>
                        <li>✔ {props.strings["text_214"]}</li>
                        <li>✔ {props.strings["text_215"]}</li>
                        <li>✔ {props.strings["text_216"]}</li>
                        <li>✔ {props.strings["text_217"]}</li>
                        <li>✔ {props.strings["text_220"]}</li>
                        <li>✔ {props.strings["text_191"]}</li>
                        <li>✔ {props.strings["text_281"]}</li>
                        <li>✔ {props.strings["text_282"]}</li>
                        <li>✔ {props.strings["text_204"]}</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
}

BasicTabs.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(BasicTabs));
