import { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { MdArrowBackIosNew } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Lightbox from "react-image-lightbox";
import PremiumRoomandLounge from "../components/PremiumRoomandLounge/PremiumRoomandLounge";
import RoomDetail from "../components/RoomDetail/RoomDetail";
import RoomDetailPremium from "../components/RoomDetailPremium/RoomDetailPremium";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../components/Footer/Footer";
import "../assets/css/Rooms/Rooms.css";
import { MdAdminPanelSettings } from "react-icons/md";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import AOS from "aos";
import "aos/dist/aos.css";
// import required modules
import { FreeMode } from "swiper";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Header from "../components/Header/Header";
import { Tooltip as ReactTooltip } from "react-tooltip";
function Test(props) {
  const [width, setWidth] = useState(350);
  const [widthTwo, setWidthTwo] = useState(350);
  const [widthThree, setWidthThree] = useState(350);
  const [clicked, setClicked] = useState(false);
  const [clickedTwo, setClickedTwo] = useState(false);
  const [clickedThree, setClickedThree] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [
    "/img/63.jpg",
    "/img/lounge_01.png",
    "/img/lounge_02.png",
    "/img/lounge_03.png",

    // Diğer resim URL'lerini buraya ekleyin
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setPhotoIndex(0);
    setIsOpen(false);
  };

  const handleClick = () => {
    if (!clicked) {
      setWidth(width + 1000);
      setClicked(true);
    }
  };

  const handleReset = () => {
    setWidth(350);
    setClicked(false);
  };

  const handleClickTwo = () => {
    if (!clickedTwo) {
      setWidthTwo(widthTwo + 1000);
      setClickedTwo(true);
    }
  };

  const handleResetTwo = () => {
    setWidthTwo(350);
    setClickedTwo(false);
  };

  const handleClickThree = () => {
    if (!clickedThree) {
      setWidthThree(widthThree + 1000);
      setClickedThree(true);
    }
  };

  const handleResetThree = () => {
    setWidthThree(350);
    setClickedThree(false);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header />
      <div className="rooms">
        <div className="container">
          <div className="room_inner pb-5">
            <div className="crowne-main d-flex justify-content-center">
              <div
                className="item_one_room"
                onClick={handleClick}
                style={{
                  width: `${width}px`,

                  transition: "width 1.5s ease",
                  display: clickedTwo || clickedThree ? "none" : "block", // conditional style
                }}
              >
                {clicked ? (
                  <div>
                    <div className="tab_header">
                      {" "}
                      <Tabs>
                        <TabList className="tab_list">
                          <Tab className="tab_one ">
                            <span className="bold-text">
                              {props.strings["text_160"]}
                            </span>{" "}
                            {props.strings["text_159"]}
                          </Tab>
                        </TabList>{" "}
                        <TabPanel>
                          {" "}
                          <RoomDetail />
                        </TabPanel>
                        <TabPanel>
                          <div className="d-flex room_lounge">
                            <div className="row">
                              <div className="col-lg-5">
                                <div>
                                  <div
                                    id="carouselExampleIndicators"
                                    className="carousel slide carousel-fade"
                                    data-mdb-ride="carousel"
                                  >
                                    <div className="carousel-inner mb-5">
                                      {images.map((image, index) => (
                                        <div
                                          key={index}
                                          className={`carousel-item ${
                                            index === 0 ? "active" : ""
                                          }`}
                                          onClick={() => openLightbox(index)}
                                        >
                                          <img
                                            src={image}
                                            className="d-block w-100"
                                            alt="..."
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    <button
                                      className="carousel-control-prev"
                                      type="button"
                                      data-mdb-target="#carouselExampleIndicators"
                                      data-mdb-slide="prev"
                                    >
                                      <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                      />
                                      <span className="visually-hidden">
                                        Previous
                                      </span>
                                    </button>
                                    <button
                                      className="carousel-control-next"
                                      type="button"
                                      data-mdb-target="#carouselExampleIndicators"
                                      data-mdb-slide="next"
                                    >
                                      <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                      />
                                      <span className="visually-hidden">
                                        Next
                                      </span>
                                    </button>
                                    <div className="carousel-indicators">
                                      {images.map((image, index) => (
                                        <button
                                          key={index}
                                          type="button"
                                          data-mdb-target="#carouselExampleIndicators"
                                          data-mdb-slide-to={index}
                                          className={
                                            index === photoIndex ? "active" : ""
                                          }
                                          aria-current={
                                            index === photoIndex
                                              ? "true"
                                              : "false"
                                          }
                                          aria-label={`Slide ${index + 1}`}
                                          style={{ width: 100 }}
                                          onClick={() => openLightbox(index)}
                                        >
                                          <img
                                            className="d-block w-100"
                                            src={image}
                                            alt={`Slide ${index + 1}`}
                                          />
                                        </button>
                                      ))}
                                    </div>
                                  </div>

                                  {isOpen && (
                                    <Lightbox
                                      mainSrc={images[photoIndex]}
                                      nextSrc={
                                        images[(photoIndex + 1) % images.length]
                                      }
                                      prevSrc={
                                        images[
                                          (photoIndex + images.length - 1) %
                                            images.length
                                        ]
                                      }
                                      onCloseRequest={() => setIsOpen(false)}
                                      onMovePrevRequest={() =>
                                        setPhotoIndex(
                                          (photoIndex + images.length - 1) %
                                            images.length
                                        )
                                      }
                                      onMoveNextRequest={() =>
                                        setPhotoIndex(
                                          (photoIndex + 1) % images.length
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div
                                  data-aos="fade-right"
                                  data-aos-duration="1000"
                                  data-aos-delay="600"
                                  className="rooms_lounge_text_section"
                                >
                                  <div className="res_breakfast">
                                    <h6 className="bold-text pt-3">
                                      {props.strings["text_163"]}
                                    </h6>
                                    <p>
                                      06.30 - 10.30 {props.strings["text_164"]}
                                    </p>
                                  </div>
                                  <div className="room_text_section_lounge">
                                    <div className="col-lg-6 d-flex flex-column justify-content-start">
                                      <h6 className="bold-text">
                                        {props.strings["text_165"]}
                                      </h6>
                                      <p>
                                        10.30 - 22.30 <br />
                                        <ul>
                                          <li>✔ {props.strings["text_166"]}</li>
                                          <li>✔ {props.strings["text_167"]}</li>
                                          <li>✔ {props.strings["text_168"]}</li>
                                          <li>✔ {props.strings["text_169"]}</li>
                                          <li>✔ {props.strings["text_170"]}</li>
                                          <li>✔ {props.strings["text_171"]}</li>
                                          <li>✔ {props.strings["text_172"]}</li>
                                          <li>✔ {props.strings["text_173"]}</li>
                                          <li>✔ {props.strings["text_174"]}</li>
                                        </ul>
                                      </p>
                                    </div>
                                    <div className="col-lg-6">
                                      <h6 className="bold-text">
                                        {props.strings["text_175"]}
                                      </h6>
                                      <p>
                                        18.00 - 20.00
                                        <ul>
                                          <li>✔{props.strings["text_176"]}</li>
                                          <li>✔{props.strings["text_177"]}</li>
                                          <li>✔{props.strings["text_178"]}</li>
                                          <li>✔{props.strings["text_167"]}</li>
                                          <li>✔{props.strings["text_180"]}</li>
                                        </ul>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          {" "}
                          <RoomDetailPremium />
                        </TabPanel>
                      </Tabs>
                      <div class="close-container-x" onClick={handleReset}>
                        <div class="leftright-x"></div>
                        <div class="rightleft-x"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="room_card">
                    <div className="room_card_inner">
                      <h6>
                        {" "}
                        <span className="bold-text">
                          {props.strings["text_160"]}
                        </span>{" "}
                        {props.strings["text_159"]}
                      </h6>
                      <p>
                        <ul>
                          <li> 》{props.strings["text_324"]}</li>
                          <li> 》{props.strings["text_325"]}</li>{" "}
                          <li> 》{props.strings["text_326"]}</li>{" "}
                          <li> 》{props.strings["text_327"]}</li>
                          <li style={{ visibility: "hidden" }}>
                            {" "}
                            》Accessible Two Single Beds
                          </li>
                        </ul>
                      </p>
                      <p>{props.strings["text_289"]}</p>
                    </div>
                    <div className="col-lg-6 room_card_bg"> </div>
                  </div>
                )}
              </div>
            </div>
            <div className="crowne-main d-flex justify-content-center">
              <div
                className="item_three_room"
                onClick={handleClickThree}
                style={{
                  width: `${widthThree}px`,

                  transition: "width 1.5s ease",
                  display: clicked || clickedTwo ? "none" : "block", // conditional style
                }}
              >
                {clickedThree ? (
                  <div>
                    <div className="tab_header">
                      {" "}
                      <Tabs defaultIndex={0}>
                        <TabList className="tab_list">
                          <Tab className="tab_three">
                            <span className="bold-text">
                              {props.strings["text_161"]}{" "}
                            </span>{" "}
                            {props.strings["text_283"]}
                          </Tab>
                          <Tab className="tab_three">
                            <span className="bold-text">
                              {props.strings["text_161"]}{" "}
                            </span>{" "}
                            {props.strings["text_162"]}
                          </Tab>
                        </TabList>{" "}
                        <TabPanel>
                          <PremiumRoomandLounge />
                        </TabPanel>
                        <TabPanel>
                          <div className="d-flex room_lounge">
                            <div className="row">
                              <div className="col-lg-5">
                                <div>
                                  <div
                                    id="carouselExampleIndicators"
                                    className="carousel slide carousel-fade"
                                    data-mdb-ride="carousel"
                                  >
                                    <div className="carousel-inner mb-5">
                                      {images.map((image, index) => (
                                        <div
                                          key={index}
                                          className={`carousel-item ${
                                            index === 0 ? "active" : ""
                                          }`}
                                          onClick={() => openLightbox(index)}
                                        >
                                          <img
                                            src={image}
                                            className="d-block w-100"
                                            alt="..."
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    <button
                                      className="carousel-control-prev"
                                      type="button"
                                      data-mdb-target="#carouselExampleIndicators"
                                      data-mdb-slide="prev"
                                    >
                                      <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                      />
                                      <span className="visually-hidden">
                                        Previous
                                      </span>
                                    </button>
                                    <button
                                      className="carousel-control-next"
                                      type="button"
                                      data-mdb-target="#carouselExampleIndicators"
                                      data-mdb-slide="next"
                                    >
                                      <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                      />
                                      <span className="visually-hidden">
                                        Next
                                      </span>
                                    </button>
                                    <div className="carousel-indicators">
                                      {images.map((image, index) => (
                                        <button
                                          key={index}
                                          type="button"
                                          data-mdb-target="#carouselExampleIndicators"
                                          data-mdb-slide-to={index}
                                          className={
                                            index === photoIndex ? "active" : ""
                                          }
                                          aria-current={
                                            index === photoIndex
                                              ? "true"
                                              : "false"
                                          }
                                          aria-label={`Slide ${index + 1}`}
                                          style={{ width: 100 }}
                                          onClick={() => openLightbox(index)}
                                        >
                                          <img
                                            className="d-block w-100"
                                            src={image}
                                            alt={`Slide ${index + 1}`}
                                          />
                                        </button>
                                      ))}
                                    </div>
                                  </div>

                                  {isOpen && (
                                    <Lightbox
                                      mainSrc={images[photoIndex]}
                                      nextSrc={
                                        images[(photoIndex + 1) % images.length]
                                      }
                                      prevSrc={
                                        images[
                                          (photoIndex + images.length - 1) %
                                            images.length
                                        ]
                                      }
                                      onCloseRequest={() => setIsOpen(false)}
                                      onMovePrevRequest={() =>
                                        setPhotoIndex(
                                          (photoIndex + images.length - 1) %
                                            images.length
                                        )
                                      }
                                      onMoveNextRequest={() =>
                                        setPhotoIndex(
                                          (photoIndex + 1) % images.length
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div
                                  data-aos="fade-right"
                                  data-aos-duration="1000"
                                  data-aos-delay="600"
                                  className="rooms_lounge_text_section"
                                >
                                  <div className="res_breakfast">
                                    <h6 className="bold-text pt-3">
                                      {props.strings["text_163"]}
                                    </h6>
                                    <p>
                                      06.30 - 10.30 {props.strings["text_164"]}
                                    </p>
                                  </div>
                                  <div className="room_text_section_lounge">
                                    <div className="col-lg-6 d-flex flex-column justify-content-start">
                                      <h6 className="bold-text">
                                        {props.strings["text_165"]}
                                      </h6>
                                      <p>
                                        10.30 - 22.30 <br />
                                        <ul>
                                          <li>✔ {props.strings["text_166"]}</li>
                                          <li>✔ {props.strings["text_167"]}</li>
                                          <li>✔ {props.strings["text_168"]}</li>
                                          <li>✔ {props.strings["text_169"]}</li>
                                          <li>✔ {props.strings["text_170"]}</li>
                                          <li>✔ {props.strings["text_171"]}</li>
                                          <li>✔ {props.strings["text_172"]}</li>
                                          <li>✔ {props.strings["text_173"]}</li>
                                          <li>✔ {props.strings["text_174"]}</li>
                                        </ul>
                                      </p>
                                    </div>
                                    <div className="col-lg-6">
                                      <h6 className="bold-text">
                                        {props.strings["text_175"]}
                                      </h6>
                                      <p>
                                        18.00 - 20.00
                                        <ul>
                                          <li>✔{props.strings["text_176"]}</li>
                                          <li>✔{props.strings["text_177"]}</li>
                                          <li>✔{props.strings["text_178"]}</li>
                                          <li>✔{props.strings["text_167"]}</li>
                                          <li>✔{props.strings["text_180"]}</li>
                                        </ul>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                      <div class="close-container-x" onClick={handleResetThree}>
                        <div class="leftright-x"></div>
                        <div class="rightleft-x"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="room_card">
                    <div className="room_card_three_inner_header">
                      <h6>
                        <span className="bold-text">
                          {props.strings["text_161"]}{" "}
                        </span>{" "}
                        {props.strings["text_283"]}
                      </h6>
                      <p>
                        <ul>
                          <li> 》{props.strings["text_336"]}</li>
                          <li> 》{props.strings["text_337"]}</li>
                          <li> 》{props.strings["text_338"]}</li>
                          <li> 》{props.strings["text_339"]}</li>
                          <li> 》{props.strings["text_340"]}</li>
                        </ul>
                      </p>
                      <p>{props.strings["text_147"]}</p>
                    </div>
                    <div className="col-lg-6 room_card_bg_three"> </div>
                  </div>
                )}
              </div>
            </div>
            <div className="crowne-main d-flex justify-content-center">
              <div
                className="item_two_room"
                onClick={handleClickTwo}
                style={{
                  width: `${widthTwo}px`,

                  transition: "width 1.5s ease",
                  display: clicked || clickedThree ? "none" : "block", // conditional style
                }}
              >
                {clickedTwo ? (
                  <div>
                    <div className="tab_header">
                      {" "}
                      <Tabs defaultIndex={1}>
                        <TabList className="tab_list">
                          <Tab className="tab_two">
                            <span className="bold-text">
                              {props.strings["text_158"]}
                            </span>{" "}
                            {props.strings["text_159"]}
                          </Tab>
                        </TabList>{" "}
                        <TabPanel>
                          {" "}
                          <RoomDetailPremium />
                        </TabPanel>
                        <TabPanel>
                          {" "}
                          <RoomDetail />
                        </TabPanel>
                        <TabPanel>
                          <RoomDetail />
                          {/*    <div className="d-flex room_lounge">
                            <div className="row">
                              <div className="col-lg-5">
                                <div>
                                  <div
                                    id="carouselExampleIndicators"
                                    className="carousel slide carousel-fade"
                                    data-mdb-ride="carousel"
                                  >
                                    <div className="carousel-inner mb-5">
                                      {images.map((image, index) => (
                                        <div
                                          key={index}
                                          className={`carousel-item ${
                                            index === 0 ? "active" : ""
                                          }`}
                                          onClick={() => openLightbox(index)}
                                        >
                                          <img
                                            src={image}
                                            className="d-block w-100"
                                            alt="..."
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    <button
                                      className="carousel-control-prev"
                                      type="button"
                                      data-mdb-target="#carouselExampleIndicators"
                                      data-mdb-slide="prev"
                                    >
                                      <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                      />
                                      <span className="visually-hidden">
                                        Previous
                                      </span>
                                    </button>
                                    <button
                                      className="carousel-control-next"
                                      type="button"
                                      data-mdb-target="#carouselExampleIndicators"
                                      data-mdb-slide="next"
                                    >
                                      <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                      />
                                      <span className="visually-hidden">
                                        Next
                                      </span>
                                    </button>
                                    <div className="carousel-indicators">
                                      {images.map((image, index) => (
                                        <button
                                          key={index}
                                          type="button"
                                          data-mdb-target="#carouselExampleIndicators"
                                          data-mdb-slide-to={index}
                                          className={
                                            index === photoIndex ? "active" : ""
                                          }
                                          aria-current={
                                            index === photoIndex
                                              ? "true"
                                              : "false"
                                          }
                                          aria-label={`Slide ${index + 1}`}
                                          style={{ width: 100 }}
                                          onClick={() => openLightbox(index)}
                                        >
                                          <img
                                            className="d-block w-100"
                                            src={image}
                                            alt={`Slide ${index + 1}`}
                                          />
                                        </button>
                                      ))}
                                    </div>
                                  </div>

                                  {isOpen && (
                                    <Lightbox
                                      mainSrc={images[photoIndex]}
                                      nextSrc={
                                        images[(photoIndex + 1) % images.length]
                                      }
                                      prevSrc={
                                        images[
                                          (photoIndex + images.length - 1) %
                                            images.length
                                        ]
                                      }
                                      onCloseRequest={() => setIsOpen(false)}
                                      onMovePrevRequest={() =>
                                        setPhotoIndex(
                                          (photoIndex + images.length - 1) %
                                            images.length
                                        )
                                      }
                                      onMoveNextRequest={() =>
                                        setPhotoIndex(
                                          (photoIndex + 1) % images.length
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div
                                  data-aos="fade-right"
                                  data-aos-duration="1000"
                                  data-aos-delay="600"
                                  className="rooms_lounge_text_section"
                                >
                                  <div className="res_breakfast">
                                    <h6 className="bold-text pt-3">
                                      {props.strings["text_163"]}
                                    </h6>
                                    <p>
                                      06.30 - 10.30 {props.strings["text_164"]}
                                    </p>
                                  </div>
                                  <div className="room_text_section_lounge">
                                    <div className="col-lg-6 d-flex flex-column justify-content-start">
                                      <h6 className="bold-text">
                                        {props.strings["text_165"]}
                                      </h6>
                                      <p>
                                        10.30 - 22.30 <br />
                                        <ul>
                                          <li>✔ {props.strings["text_166"]}</li>
                                          <li>✔ {props.strings["text_167"]}</li>
                                          <li>✔ {props.strings["text_168"]}</li>
                                          <li>✔ {props.strings["text_169"]}</li>
                                          <li>✔ {props.strings["text_170"]}</li>
                                          <li>✔ {props.strings["text_171"]}</li>
                                          <li>✔ {props.strings["text_172"]}</li>
                                          <li>✔ {props.strings["text_173"]}</li>
                                          <li>✔ {props.strings["text_174"]}</li>
                                        </ul>
                                      </p>
                                    </div>
                                    <div className="col-lg-6">
                                      <h6 className="bold-text">
                                        {props.strings["text_175"]}
                                      </h6>
                                      <p>
                                        18.00 - 20.00
                                        <ul>
                                          <li>✔{props.strings["text_176"]}</li>
                                          <li>✔{props.strings["text_177"]}</li>
                                          <li>✔{props.strings["text_178"]}</li>
                                          <li>✔{props.strings["text_167"]}</li>
                                          <li>✔{props.strings["text_180"]}</li>
                                          <li>✔{props.strings["text_181"]}</li>
                                        </ul>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </TabPanel>
                      </Tabs>
                      <div class="close-container-x" onClick={handleResetTwo}>
                        <div class="leftright-x"></div>
                        <div class="rightleft-x"></div>
                      </div>
                    </div>

                    <div className="room_card_standard d-flex"></div>
                  </div>
                ) : (
                  <div className="room_card">
                    <div className="room_card_inner">
                      <h6>
                        {" "}
                        <span className="bold-text">
                          {props.strings["text_158"]}
                        </span>{" "}
                        {props.strings["text_159"]}
                      </h6>
                      <p>
                        <ul>
                          <li> 》{props.strings["text_332"]}</li>
                          <li> 》{props.strings["text_333"]}</li>{" "}
                          <li> 》{props.strings["text_334"]}</li>{" "}
                          <li> 》{props.strings["text_335"]}</li>
                          <li style={{ visibility: "hidden" }}>
                            {" "}
                            》Presidential Suite
                          </li>
                        </ul>
                      </p>
                      <p>{props.strings["text_146"]}</p>
                    </div>
                    <div className="col-lg-6 room_card_bg_two"> </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row pb-5 services_div d-flex justify-content-center ">
            <div className="container">
              <div className="col-lg-12 services">
                {" "}
                <h4> {props.strings["text_18"]} </h4>
                <div className="services_inner">
                  <div className="">
                    <img
                      src="/img/icons/00-03.svg"
                      className="services_icon services_icon_one"
                    />
                    <h6 data-tooltip-id="my-tooltip-1">
                      7/24 <br /> {props.strings["text_148"]}
                    </h6>
                  </div>

                  <ReactTooltip
                    id="my-tooltip-1"
                    place="bottom"
                    content={props.strings["text_310"]}
                    variant="light"
                    className="custom-tooltip"
                  />
                  <div className="">
                    <img
                      src="/img/icons/00-01.svg"
                      className="services_icon services_icon_two"
                    />
                    <h6 data-tooltip-id="my-tooltip-2">
                      {" "}
                      {props.strings["text_149"]}
                    </h6>
                  </div>
                  <ReactTooltip
                    id="my-tooltip-2"
                    place="bottom"
                    content={props.strings["text_311"]}
                    variant="light"
                    className="custom-tooltip"
                  />

                  <div className="">
                    <img
                      src="/img/icons/00-05.svg"
                      className="services_icon services_icon_three"
                    />
                    <h6 data-tooltip-id="my-tooltip-3">
                      200 Mbps <br /> Internet
                    </h6>
                  </div>
                  <ReactTooltip
                    id="my-tooltip-3"
                    place="bottom"
                    content={props.strings["text_312"]}
                    variant="light"
                    className="custom-tooltip"
                  />
                  <div className="">
                    <img
                      src="/img/icons/00-02.svg"
                      className="services_icon services_icon_four"
                    />{" "}
                    <h6 data-tooltip-id="my-tooltip-4">IPTV</h6>
                  </div>
                  <ReactTooltip
                    id="my-tooltip-4"
                    place="bottom"
                    content={props.strings["text_313"]}
                    variant="light"
                    className="custom-tooltip"
                  />
                  <div className="">
                    {" "}
                    <img
                      src="/img/icons/00-04.svg"
                      className="services_icon services_icon_five"
                    />
                    <h6 data-tooltip-id="my-tooltip-5">
                      {props.strings["text_150"]} <br />
                      {props.strings["text_151"]}
                    </h6>
                  </div>
                  <ReactTooltip
                    id="my-tooltip-5"
                    place="bottom"
                    content={props.strings["text_314"]}
                    variant="light"
                    className="custom-tooltip"
                  />
                  <div className="">
                    {" "}
                    <img
                      src="/img/icons/00-07.svg"
                      className="services_icon services_icon_six"
                    />
                    <h6 data-tooltip-id="my-tooltip-6">
                      AC/DC <br />
                      {props.strings["text_152"]}
                    </h6>
                  </div>
                  <ReactTooltip
                    id="my-tooltip-6"
                    place="bottom"
                    content={props.strings["text_315"]}
                    variant="light"
                    className="custom-tooltip"
                  />
                  <div className="">
                    <img
                      src="/img/icons/00-06.svg"
                      className="services_icon services_icon_seven"
                    />
                    <h6 data-tooltip-id="my-tooltip-7">
                      {props.strings["text_153"]} <br />
                      {props.strings["text_154"]}
                    </h6>
                  </div>
                  <ReactTooltip
                    id="my-tooltip-7"
                    place="bottom"
                    content={props.strings["text_316"]}
                    variant="light"
                    className="custom-tooltip"
                  />
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="row membership pt-5 d-flex justify-content-center">
            <div className="col-lg-5 membership_inner">
              <h4> {props.strings["text_155"]}</h4>
              <p>{props.strings["text_156"]}</p>{" "}
              <a
                className="white_link"
                target="_blank"
                style={{ color: "white" }}
                href="https://www.ihg.com/hotels/tr/tr/reservation"
              >
                <button className="btn rooms_btn_ihg">
                  {" "}
                  {props.strings["text_114"]}
                </button>{" "}
              </a>
            </div>
            <div className="col-lg-5 d-flex justify-content-center">
              <img className="ihg_one" src="/img/ihg_one.png" />
            </div>
          </div>
        </div>
      </div>

      <Footer className="pt-5" />
    </>
  );
}

Test.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Test));
