import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const PresentationSection = (props) => {
  return (
    <section class="presentation">
      <div class="presentation_header section_header">
    
   
        <h5 class="section_header-title pb-3" data-aos="fade-right">
        {props.strings["text_270"]}
        </h5>
        <button className="btn discover_btn">
          <Link to="/rooms">
          {props.strings["discover"]}
          </Link>
        </button>
        <div class="presentation_slider-nav swiper-overlay-nav swiper-nav">
          <a class="swiper-button-prev nav-item swiper-button-prevone" href="#">
            <i class="icon-chevron-left"></i>
          </a>
          <a class="swiper-button-next nav-item swiper-button-nextone" href="#">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>
      <Swiper
      loop={true}
        slidesPerView={3}
        modules={[Navigation]}
        className="presentation_slider swiper"
        navigation={{
          nextEl: ".presentation_slider-nav .swiper-button-nextone",
          prevEl: ".presentation_slider-nav .swiper-button-prevone",
        }}
      >
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
           
                <img
                  class="lazy room-hover"
                  data-src="/img/rooms/rooms_01.webp"
                  src="/img/rooms/rooms_01.webp"
                  alt="Three Chocolates"
                />
            </div>
         
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/rooms/rooms_03.webp"
                  src="/img/rooms/rooms_03.webp"
                  alt="Three Chocolates"
                />
            </div>
           
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/rooms/rooms_02.webp"
                  src="/img/rooms/rooms_02.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/rooms/rooms_02_.webp"
                  src="/img/rooms/rooms_02_.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/rooms/rooms_03_.webp"
                  src="/img/rooms/rooms_03_.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/rooms/rooms_01__.webp"
                  src="/img/rooms/rooms_01_.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
     
      </Swiper>
    </section>
  );
};

PresentationSection.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(PresentationSection));
