import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const HeroSectionStart = (props) => {
  return (
    <section class="hero hero_home">
      <div class="container medium-screen">
        <div class="hero_main hero_home_main">
          <div class="section_header col-12">
            <span class="section_header-subtitle section_header-subtitle-two" data-aos="fade-down">
              {props.strings["welcome"]}
            </span>
            <h1 class="section_header-title-two small-h1 " data-aos="fade-up">
              Crowne Plaza Ankara
            </h1>
            <p class="section_header-text text--medium section_header-text-two" data-aos="fade-right">
              {props.strings["text_11"]}
            </p>
          </div>
        </div>
        <Swiper
          loop={true}
          modules={[Navigation]}
          className="hero_slider swiper col-lg-6"
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
        >
          <SwiperSlide>
            {" "}
            <picture>
              <source
                data-srcset="/img/slider/home_slider_02.webp"
                srcSet="/img/slider/home_slider_02.webp"
                type="image/webp"
              />
              <img
                className="lazy"
                data-src="/img/slider/home_slider_02.webp"
                src="/img/slider/home_slider_02.webp"
                alt="media"
              />
            </picture>
          </SwiperSlide>

          <SwiperSlide>
            {" "}
            <picture>
              <source
                data-srcset="/img/slider/home_slider_04.webp"
                srcSet="/img/slider/home_slider_04.webp"
                type="image/webp"
              />
              <img
                className="lazy"
                data-src="/img/slider/home_slider_04.webp"
                src="/img/slider/home_slider_04.webp"
                alt="media"
              />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <picture>
              <source
                data-srcset="/img/slider/home_slider_03.webp"
                srcSet="/img/slider/home_slider_03.webp"
                type="image/webp"
              />
              <img
                className="lazy"
                data-src="/img/slider/home_slider_03.webp"
                src="/img/slider/home_slider_03.webp"
                alt="media"
              />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <picture>
              <source
                data-srcset="/img/slider/home_slider_05.webp"
                srcSet="/img/slider/home_slider_05.webp"
                type="image/webp"
              />
              <img
                className="lazy"
                data-src="/img/slider/home_slider_05.webp"
                src="/img/slider/home_slider_05.webp"
                alt="media"
              />
            </picture>
          </SwiperSlide>

          <div className="hero_slider-nav swiper-overlay-nav swiper-nav">
            <a className="swiper-button-prev nav-item">
              <i className="icon-chevron-left" />
            </a>
            <a className="swiper-button-next nav-item" href="#">
              <i className="icon-chevron-right" />
            </a>
          </div>
        </Swiper>
      </div>
    </section>
  );
};

HeroSectionStart.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(HeroSectionStart));
