import React, { useEffect, useState } from "react";
import LightboxGallery from "react-lightbox-gallery";
import { Link } from "react-router-dom";
import "./MenuSection.css";import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";


const portfolio = [
  {
    name: "/img/home_tab/exterior_01.webp",
    category: ["All", "Exterior"],
    thumbnail: "/img/home_tab/exterior_01_thumbnail.webp",
    original: "/img/home_tab/exterior_01_original.webp"
  },
  {
    name: "/img/home_tab/exterior_02.webp",
    category: ["All", "Exterior"],
    
  },
  {
    name: "/img/home_tab/exterior_03.webp",
    category: ["All", "Exterior"],
  },
  {
    name: "/img/home_tab/exterior_04.webp",
    category: ["All", "Exterior"],
  },
  {
    name: "/img/home_tab/interior_01.webp",
    category: ["All", "Interior"],
  },
  {
    name: "/img/home_tab/interior_02.webp",
    category: ["All", "Interior"],
  },
  {
    name: "/img/home_tab/interior_03.webp",
    category: ["All", "Interior"],
  },
  {
    name: "/img/home_tab/interior_04.webp",
    category: ["All", "Interior"],
  },
  {
    name: "/img/home_tab/rooms_01.webp",
    category: ["All", "Rooms"],
  },
  {
    name: "/img/home_tab/rooms_02.webp",
    category: ["All", "Rooms"],
  },
  {
    name: "/img/home_tab/rooms_03.webp",
    category: ["All", "Rooms"],
  },
  {
    name: "/img/home_tab/rooms_04.webp",
    category: ["All", "Rooms"],
  },
  {
    name: "/img/home_tab/meeting_01.webp",
    category: ["All", "Meeting & Events"],
  },
  {
    name: "/img/home_tab/meeting_02.webp",
    category: ["All", "Meeting & Events"],
  },
  {
    name: "/img/home_tab/meeting_03.webp",
    category: ["All", "Meeting & Events"],
  },
  {
    name: "/img/home_tab/meeting_04.webp",
    category: ["All", "Meeting & Events"],
  },
  {
    name: "/img/home_tab/aura_01.webp",
    category: ["All", "Dining"],
  },
  {
    name: "/img/home_tab/aura_02.webp",
    category: ["All", "Dining"],
  },
  {
    name: "/img/home_tab/one_bar_01.webp",
    category: ["All", "Dining"],
  },
  {
    name: "/img/home_tab/one_bar_02.webp",
    category: ["All", "Dining"],
  },
  {
    name: "/img/home_tab/spa_01.webp",
    category: ["All", "Spa"],
  },
  {
    name: "/img/home_tab/spa_02.webp",
    category: ["All", "Spa"],
  },
  {
    name: "/img/home_tab/spa_03.webp",
    category: ["All", "Spa"],
  },
  {
    name: "/img/home_tab/spa_04.webp",
    category: ["All", "Spa"],
  },
];

const MenuSection = (props) => {
  const [filter, setFilter] = useState("Exterior");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(portfolio);
  }, []);

  useEffect(() => {
    setProjects([]);

    const filtered = portfolio.map((p) => ({
      ...p,
      filtered: p.category.includes(filter),
    }));
    setProjects(filtered);
  }, [filter]);

  return (
    <section className="menu section--nopb mb-5">
      <div className="container">
        <div className="section_header centered">
          <h3 className="section_header-title" data-aos="fade-up" data-aos-delay="50">
          {props.strings["text_6"]}
          </h3>
          <p className="section_header-text" data-aos="fade-up" data-aos-delay="100">
          {props.strings["text_277"]}
          </p>
        </div>
        <div className="col-12">
          {/* <div className="portfolio__labels">
         
            <a
              className={filter === "Exterior" ? "active" : ""}
              onClick={() => setFilter("Exterior")}
            >
              Exterior
            </a>
            <a
              className={filter === "Interior" ? "active" : ""}
              onClick={() => setFilter("Interior")}
            >
              Interior
            </a>
            <a
              className={filter === "Rooms" ? "active" : ""}
              onClick={() => setFilter("Rooms")}
            >
              Rooms
            </a>
            <a
              className={filter === "Dining" ? "active" : ""}
              onClick={() => setFilter("Dining")}
            >
              Dining
            </a>
            <a
              className={filter === "Meeting & Events" ? "active" : ""}
              onClick={() => setFilter("Meeting & Events")}
            >
              Meeting & Events
            </a>
            <a
              className={filter === "Spa" ? "active" : ""}
              onClick={() => setFilter("Spa")}
            >
              Spa
            </a>
          </div>
          <div className="portfolio__container">
          
              {projects.map((item) =>
                item.filtered === true ? (
                  <img
                    className="home_gallery"
                    src={item.name}
                    key={item.name}
                    alt={item.name}
                  />
                ) : (
                  ""
                )
              )}
           
          </div> */}
          <div className="d-flex justify-content-center">
          <button className="btn gallery_home_button">
<Link to="/gallery">
{props.strings["text_6"]}
</Link>
          </button>
          </div>
        </div>
      </div>
    </section>
  );
};

MenuSection.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(MenuSection));
