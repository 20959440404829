import React from "react";
import Header from "../components/Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../assets/css/Contact/Contact.css";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import Footer from "../components/Footer/Footer";
import { useEffect, useRef } from "react";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { WiDirectionUpLeft } from "react-icons/wi";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Contact = (props) => {
  const form = useRef();
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rawd0re",
        "template_1g64a0f",
        form.current,
        "CI_hKHI3tXoC1Lr4u"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }

  return (
    <div className="contact">
      <Header />

      <ToastContainer />

      <div className="row m-0 ">
        <div className="col-lg-12">
          <div className="tab_section">
            <Tabs>
              <TabList className="tab_list">
                <Tab className="tab_one "> {props.strings["text_96"]}</Tab>
                <Tab className="tab_two">{props.strings["text_97"]}</Tab>
                <Tab className="tab_two">{props.strings["text_109"]}</Tab>
              </TabList>{" "}
              <TabPanel>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="right_section pt-3 pb-5">
                      <div className="mobil_center">
                        {" "}
                        <img
                          className="contact_crowne_logo"
                          src="/img/crowne_logo.svg"
                        />
                      </div>

                      <h5 className="pt-3 text_pink">
                        {" "}
                        {props.strings["text_98"]}
                      </h5>
                      <h6>Macun, 171. Sk. 2/1, 06374</h6>
                      <h6>Yenimahalle/Ankara</h6>
                      <h5 className="pt-3 text_pink">
                        {" "}
                        {props.strings["text_99"]}
                      </h5>
                      <h6>
                        {" "}
                        <a className="black_link" href="tel:+03123200000">
                          {" "}
                          +90 312 320 00 00
                        </a>
                      </h6>
                      <h6>
                        <a
                          className="contact_mail_link"
                          href="mailto: reservation@crowneplazaankara.com.tr"
                        >
                          reservation@crowneplazaankara.com.tr
                        </a>
                      </h6>
                      <h5 className="pt-3 text_pink">
                        {" "}
                        {props.strings["text_100"]}
                      </h5>

                      <h6 className="pb-3">
                        <a
                          className="contact_mail_link"
                          href="mailto: info@crowneplazaankara.com.tr"
                        >
                          info@crowneplazaankara.com.tr
                        </a>
                      </h6>
                      <div className="social_media">
                        {" "}
                        <a
                          href="https://www.instagram.com/crowneplazaankara/"
                          target="_blank"
                        >
                          <FaInstagram className="social_media_icon_contact" />
                        </a>
                        <a
                          href="https://www.facebook.com/crowneplazaankarahotel/"
                          target="_blank"
                        >
                          {" "}
                          <FaFacebookSquare className="social_media_icon_contact" />
                        </a>
                        <a
                          href="https://tr.linkedin.com/company/crowne-plaza-ankara"
                          target="_blank"
                        >
                          {" "}
                          <FaLinkedin className="social_media_icon_contact" />
                        </a>
                        <IoLogoWhatsapp className="social_media_icon_contact" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="left_section">
                      <form
                        onSubmit={sendEmail}
                        ref={form}
                        class="contacts_form d-flex flex-column"
                        action="contacts.php"
                        method="post"
                        data-type="contacts"
                      >
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="text"
                            name="name_surname"
                            id="contactsFirstName"
                          />
                          <label
                            className="contact_label"
                            for="contactsFirstName"
                          >
                            {props.strings["text_91"]}
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="number"
                            name="phone"
                            id="contactsLastName"
                          />
                          <label
                            className="contact_label"
                            for="contactsLastName"
                          >
                            {props.strings["text_92"]}
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="text"
                            data-type="email"
                            name="email"
                            id="contactsEmail"
                          />
                          <label className="contact_label" for="contactsEmail">
                            Email
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="text"
                            name="subject"
                            id="contactsFirstName"
                          />
                          <label
                            className="contact_label"
                            for="contactsFirstName"
                          >
                            {props.strings["text_94"]}
                          </label>
                        </div>
                        <div class="form-group">
                          <textarea
                            class="field required"
                            required
                            name="message"
                            id="contactsMessage"
                          ></textarea>
                          <label
                            className="contact_label"
                            for="contactsMessage"
                          >
                            {props.strings["text_95"]}
                          </label>
                        </div>
                        <button class="btn send_button" type="submit">
                          {props.strings["text_269"]}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <Tabs>
                  <TabList className="tab_list">
                    <Tab className="tab_one_directions ">Anıtkabir</Tab>
                    <Tab className="tab_one_directions ">Esenboğa</Tab>
                    <Tab className="tab_two_directions">AŞTİ</Tab>
                    <Tab className="tab_three_directions">Gar</Tab>
                    <Tab className="tab_four_directions">Söğütözü</Tab>
                    <Tab className="tab_four_directions">Çayyolu</Tab>
                    <Tab className="tab_four_directions">Beştepe</Tab>
                  </TabList>{" "}
                  <TabPanel className="text-center">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d24475.265687219366!2d32.78373200541383!3d39.93225783529641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x14d34f190a9cea8f%3A0xd3862ea8248d08a0!2zQW7EsXRrYWJpciwgWcO8Y2V0ZXBlLCBBa2Rlbml6IENkLiBObzozMSwgMDY1NzAgw4dhbmtheWEvQW5rYXJh!3m2!1d39.925053999999996!2d32.8369439!4m5!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sMacun%2C%20Crowne%20Plaza%20Ankara%2C%20171.%20Sokak%2C%20Yenimahalle%2FAnkara!3m2!1d39.9468368!2d32.768204!5e0!3m2!1str!2str!4v1692782858461!5m2!1str!2str"
                      width="100%"
                      style={{ height: "500px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <button className="btn directions_map_button">
                      <a
                        href="https://www.google.com/maps/dir/Esenbo%C4%9Fa+Merkez,+Esenbo%C4%9Fa+Havaalan%C4%B1,+%C3%96zal+Bulvar%C4%B1,+Akyurt%2FAnkara/Macun,+Crowne+Plaza+Ankara,+171.+Sk.+2%2F1,+06374+Yenimahalle%2FAnkara/@40.0268617,32.8004755,12z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x40820f596bf301d1:0x319987f5f53b66be!2m2!1d32.9916725!2d40.1244401!1m5!1m1!1s0x14d349e1985b0a69:0xf8ef01414a0f037d!2m2!1d32.768204!2d39.9468368?entry=ttu"
                        target="_blank"
                      >
                        {" "}
                        {props.strings["text_110"]}
                      </a>
                      <WiDirectionUpLeft className="direction_icon" />
                    </button>
                  </TabPanel>
                  <TabPanel className="text-center">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d97754.17658689889!2d32.80108369569624!3d40.03484230539311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x40820f596bf301d1%3A0x319987f5f53b66be!2zRXNlbmJvxJ9hIE1lcmtleiwgRXNlbmJvxJ9hIEhhdmFhbGFuxLEsIMOWemFsIEJ1bHZhcsSxLCBBa3l1cnQvQW5rYXJh!3m2!1d40.1244401!2d32.9916725!4m5!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sMacun%2C%20Crowne%20Plaza%20Ankara%2C%20171.%20Sk.%202%2F1%2C%2006374%20Yenimahalle%2FAnkara!3m2!1d39.9468368!2d32.768204!5e0!3m2!1str!2str!4v1685115236108!5m2!1str!2str"
                      width="100%"
                      style={{ height: "500px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <button className="btn directions_map_button">
                      <a
                        href="https://www.google.com/maps/dir/Esenbo%C4%9Fa+Merkez,+Esenbo%C4%9Fa+Havaalan%C4%B1,+%C3%96zal+Bulvar%C4%B1,+Akyurt%2FAnkara/Macun,+Crowne+Plaza+Ankara,+171.+Sk.+2%2F1,+06374+Yenimahalle%2FAnkara/@40.0268617,32.8004755,12z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x40820f596bf301d1:0x319987f5f53b66be!2m2!1d32.9916725!2d40.1244401!1m5!1m1!1s0x14d349e1985b0a69:0xf8ef01414a0f037d!2m2!1d32.768204!2d39.9468368?entry=ttu"
                        target="_blank"
                      >
                        {" "}
                        {props.strings["text_110"]}
                      </a>
                      <WiDirectionUpLeft className="direction_icon" />
                    </button>
                  </TabPanel>
                  <TabPanel className="text-center">
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d24474.867765871277!2d32.77845695651149!3d39.93337063474717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x14d34f35749a0463%3A0x637319303afa4219!2zQcWeVMSwLCBCZcWfdGVwZSwgMDY1NjAgWWVuaW1haGFsbGUvQW5rYXJh!3m2!1d39.9186871!2d32.8100028!4m5!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sMacun%2C%20Crowne%20Plaza%20Ankara%2C%20171.%20Sk.%202%2F1%2C%2006374%20Yenimahalle%2FAnkara!3m2!1d39.9468368!2d32.768204!5e0!3m2!1str!2str!4v1685115373687!5m2!1str!2str"
                      width="100%"
                      style={{ height: "500px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <button className="btn directions_map_button">
                      <a
                        href="https://www.google.com/maps/dir/A%C5%9ET%C4%B0,+Be%C5%9Ftepe,+06560+Yenimahalle%2FAnkara/Macun,+Crowne+Plaza+Ankara,+171.+Sk.+2%2F1,+06374+Yenimahalle%2FAnkara/@39.9365602,32.7784766,14z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x14d34f35749a0463:0x637319303afa4219!2m2!1d32.8100028!2d39.9186871!1m5!1m1!1s0x14d349e1985b0a69:0xf8ef01414a0f037d!2m2!1d32.768204!2d39.9468368?entry=ttu"
                        target="_blank"
                      >
                        {" "}
                        {props.strings["text_110"]}
                      </a>
                      <WiDirectionUpLeft className="direction_icon" />
                    </button>
                  </TabPanel>
                  <TabPanel className="text-center">
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d24475.36918888299!2d32.78986340650826!3d39.93196838543731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x14d3489e92c80f89%3A0xfed3b421fe9d0054!2sMustafa%20Kemal%2C%20gar%20istasyon%2C%202151.%20Caddesi%2C%20%C3%87ankaya%2FAnkara!3m2!1d39.910913!2d32.781604699999995!4m5!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sMacun%2C%20Crowne%20Plaza%20Ankara%2C%20171.%20Sk.%202%2F1%2C%2006374%20Yenimahalle%2FAnkara!3m2!1d39.9468368!2d32.768204!5e0!3m2!1str!2str!4v1685115442928!5m2!1str!2str"
                      width="100%"
                      style={{ height: "500px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <button className="btn directions_map_button">
                      <a
                        href="https://www.google.com/maps/dir/Mustafa+Kemal,+gar+istasyon,+2151.+Caddesi,+%C3%87ankaya%2FAnkara/Macun,+Crowne+Plaza+Ankara,+171.+Sk.+2%2F1,+06374+Yenimahalle%2FAnkara/@39.9319684,32.7784766,14z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x14d3489e92c80f89:0xfed3b421fe9d0054!2m2!1d32.7816047!2d39.910913!1m5!1m1!1s0x14d349e1985b0a69:0xf8ef01414a0f037d!2m2!1d32.768204!2d39.9468368?entry=ttu"
                        target="_blank"
                      >
                        {" "}
                        {props.strings["text_110"]}
                      </a>
                      <WiDirectionUpLeft className="direction_icon" />
                    </button>
                  </TabPanel>
                  <TabPanel className="text-center">
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d24474.438885144442!2d32.78565790651422!3d39.93456998415686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x14d348bb70f99417%3A0x98d2f995c6ace062!2zU8O2xJ_DvHTDtnrDvCwgw4dhbmtheWEvQW5rYXJh!3m2!1d39.9154323!2d32.7943436!4m5!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sMacun%2C%20Crowne%20Plaza%20Ankara%2C%20171.%20Sk.%202%2F1%2C%2006374%20Yenimahalle%2FAnkara!3m2!1d39.9468368!2d32.768204!5e0!3m2!1str!2str!4v1685115481917!5m2!1str!2str"
                      width="100%"
                      style={{ height: "500px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <button className="btn directions_map_button">
                      <a
                        href="https://www.google.com/maps/dir/S%C3%B6%C4%9F%C3%BCt%C3%B6z%C3%BC,+%C3%87ankaya%2FAnkara/Macun,+Crowne+Plaza+Ankara,+171.+Sk.+2%2F1,+06374+Yenimahalle%2FAnkara/@39.9321047,32.7903447,14z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x14d348bb70f99417:0x98d2f995c6ace062!2m2!1d32.7943436!2d39.9154323!1m5!1m1!1s0x14d349e1985b0a69:0xf8ef01414a0f037d!2m2!1d32.768204!2d39.9468368?entry=ttu"
                        target="_blank"
                      >
                        {" "}
                        {props.strings["text_110"]}
                      </a>
                      <WiDirectionUpLeft className="direction_icon" />
                    </button>
                  </TabPanel>
                  <TabPanel className="text-center">
                    {" "}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d48965.77944269299!2d32.69915095671535!3d39.91093199722265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x14d338938b6aa291%3A0x8a9e490af5ed48c0!2zw4dheXlvbHUsIMOHYW5rYXlhL0Fua2FyYQ!3m2!1d39.881320099999996!2d32.698371099999996!4m5!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sMacun%2C%20Crowne%20Plaza%20Ankara%2C%20171.%20Sk.%202%2F1%2C%2006374%20Yenimahalle%2FAnkara!3m2!1d39.9468368!2d32.768204!5e0!3m2!1str!2str!4v1685717415202!5m2!1str!2str"
                      width="100%"
                      style={{ height: "500px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <button className="btn directions_map_button">
                      <a
                        href="https://www.google.com/maps/dir/%C3%87ayyolu,+%C3%87ankaya%2FAnkara/Macun,+Crowne+Plaza+Ankara,+171.+Sk.+2%2F1,+06374+Yenimahalle%2FAnkara/@39.910932,32.699151,13z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x14d338938b6aa291:0x8a9e490af5ed48c0!2m2!1d32.6983711!2d39.8813201!1m5!1m1!1s0x14d349e1985b0a69:0xf8ef01414a0f037d!2m2!1d32.768204!2d39.9468368?entry=ttu"
                        target="_blank"
                      >
                        {" "}
                        {props.strings["text_110"]}
                      </a>
                      <WiDirectionUpLeft className="direction_icon" />
                    </button>
                  </TabPanel>
                  <TabPanel className="text-center">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d24476.0824912685!2d32.77040605540865!3d39.9299735364206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x14d348cc9b6c816b%3A0xe05830b6f48a8a22!2sBe%C5%9Ftepe%2C%20Yenimahalle%2FAnkara!3m2!1d39.9210589!2d32.8056285!4m5!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sMacun%2C%20Crowne%20Plaza%20Ankara%2C%20171.%20Sokak%2C%20Yenimahalle%2FAnkara!3m2!1d39.9468368!2d32.768204!5e0!3m2!1str!2str!4v1692783050719!5m2!1str!2str"
                      width="100%"
                      style={{ height: "500px" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <button className="btn directions_map_button">
                      <a
                        href="https://www.google.com/maps/dir/%C3%87ayyolu,+%C3%87ankaya%2FAnkara/Macun,+Crowne+Plaza+Ankara,+171.+Sk.+2%2F1,+06374+Yenimahalle%2FAnkara/@39.910932,32.699151,13z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x14d338938b6aa291:0x8a9e490af5ed48c0!2m2!1d32.6983711!2d39.8813201!1m5!1m1!1s0x14d349e1985b0a69:0xf8ef01414a0f037d!2m2!1d32.768204!2d39.9468368?entry=ttu"
                        target="_blank"
                      >
                        {" "}
                        {props.strings["text_110"]}
                      </a>
                      <WiDirectionUpLeft className="direction_icon" />
                    </button>
                  </TabPanel>
                </Tabs>
              </TabPanel>
              <TabPanel>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-8 col-md-12">
                    <div className="left_section">
                      <form
                        onSubmit={sendEmail}
                        ref={form}
                        class="contacts_form d-flex flex-column"
                        action="contacts.php"
                        method="post"
                        data-type="contacts"
                      >
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="text"
                            name="name_surname"
                            id="contactsFirstName"
                          />
                          <label
                            className="contact_label"
                            for="contactsFirstName"
                          >
                            {props.strings["text_91"]}
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="number"
                            name="phone"
                            id="contactsLastName"
                          />
                          <label
                            className="contact_label"
                            for="contactsLastName"
                          >
                            {props.strings["text_92"]}
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="text"
                            data-type="email"
                            name="email"
                            id="contactsEmail"
                          />
                          <label className="contact_label" for="contactsEmail">
                            Email
                          </label>
                        </div>
                        <div class="form-group">
                          <input
                            class="field required"
                            required
                            type="text"
                            name="subject"
                            id="contactsFirstName"
                          />
                          <label
                            className="contact_label"
                            for="contactsFirstName"
                          >
                            {props.strings["text_94"]}
                          </label>
                        </div>
                        <div class="form-group">
                          <textarea
                            class="field required"
                            required
                            name="message"
                            id="contactsMessage"
                          ></textarea>
                          <label
                            className="contact_label"
                            for="contactsMessage"
                          >
                            {props.strings["text_95"]}
                          </label>
                        </div>
                        <button class="btn send_button" type="submit">
                          {props.strings["text_269"]}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs> <div className="download_sheet mt-5">
          <div className="">
              {" "}
              <h5 className="">{props.strings["text_266"]}</h5>
              <p>
                <a
                  className="white_link"
                  href="mailto:sales@crowneplazaankara.com.tr"
                  target="_blank"
                >
                  {" "}
                  sales@crowneplazaankara.com.tr
                </a>
              </p>
              <p>
                {" "}
                <a className="white_link" href="tel:+03123200000">
                  +90 312 320 00 00
                </a>
              </p>
            </div>
            <div className="">
             <button className="btn pdf_button">
                {props.currentLanguageCode === "tr" ? (
                  <a href="/pdf/crowne_plaza_ankara_fact_sheet_tr.pdf" download>
                    {props.strings["text_144"]}
                  </a>
                ) : (
                  <a
                    href="/pdf/crowne_plaza_ankara_fact_sheet_eng.pdf"
                    download
                  >
                    {props.strings["text_144"]}
                  </a>
                )}
              </button>
            </div>
          </div>
          </div>
         
          <section className="mt-5" style={{ height: "500px" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3058.756282869242!2d32.765629075203016!3d39.94684088410581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sCrowne%20Plaza%20Ankara!5e0!3m2!1str!2str!4v1687261385155!5m2!1str!2str"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </section>
        </div>
       
      </div>
      <Footer />
    </div>
  );
};

Contact.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Contact));
