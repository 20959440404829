import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PresentationSectionFour = (props) => {
  return (
    <section class="presentation presentationtwo mb-5">
      <div class="presentation_header section_header">
      <div className="d-flex justify-content-center presentation_logo pb-3">
          {" "}
          <div className="col-lg-6 d-flex justify-content-end">
            <img className="home_spa_logo" src="/img/spa_logo.svg" />
          </div>
       
        </div>

        <h5 class="section_header-title pb-3" data-aos="fade-right">
        {props.strings["text_276"]}
        </h5>
        <button className="btn discover_btn">
          <Link to="/spa_fitness">
          {props.strings["discover"]} 
          </Link>
        </button>
        <div class="presentation_slider-nav swiper-overlay-nav swiper-nav">
          <a class="swiper-button-prev nav-item swiper-button-prevFour " href="#">
            <i class="icon-chevron-left"></i>
          </a>
          <a class="swiper-button-next nav-item swiper-button-nextFour" href="#">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>
      <Swiper
            loop={true}

        slidesPerView={3}
        modules={[Navigation]}
        className="presentation_slider swiper"
        navigation={{
          nextEl: ".presentation_slider-nav .swiper-button-nextFour",
          prevEl: ".presentation_slider-nav .swiper-button-prevFour",
        }}
      >
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
           
                <img
                  class="lazy room-hover"
                  data-src="/img/spa/spa_01.webp"
                  src="/img/spa/spa_01.webp"
                  alt="Three Chocolates"
                />
            </div>
         
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/spa/spa_03.webp"
                  src="/img/spa/spa_03.webp"
                  alt="Three Chocolates"
                />
            </div>
           
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/spa/spa_02.webp"
                  src="/img/spa/spa_02.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/img/spa/spa_01_.webp"
                  src="/img/spa/spa_01_.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/spa/spa/spa_04.webp"
                  src="/img/spa/spa_04.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/spa/spa/spa_05.webp"
                  src="/img/spa/spa_05.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
        <SwiperSlide className="presentation_slider-slide">
          <a class="link" href="#">
            <div class="media">
            <img
                  class="lazy room-hover"
                  data-src="/spa/spa/spa_06.webp"
                  src="/img/spa/spa_06.webp"
                  alt="Three Chocolates"
                />
            </div>
       
          </a>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

PresentationSectionFour.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(PresentationSectionFour));
