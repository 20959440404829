import React, { useState, useEffect } from "react";
import Lightroom from "react-lightbox-gallery";
import mediaImages from "../data/mediaImages.json";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { BsCaretRightFill, BsCaretLeftFill } from "react-icons/bs";
import "../assets/css/Gallery/Gallery.css";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
let CURRENT_PAGE = 0;
const ITEM_COUNT = 12;

function Media(props) {
  const { strings } = props;
  const [activetab, setActiveTab] =useState()
  const [filteredImages, setFilteredImages] = useState(mediaImages);
  const [tempImages, setTempImages] = useState(
    filteredImages.slice(0, ITEM_COUNT)
  );
  const totalPages = Math.ceil(filteredImages.length / ITEM_COUNT);

 const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab") || "All";

  const filterImages2 = () => {
    CURRENT_PAGE = 0;
    const buttons = document.getElementsByClassName("btn-filter");
    const totalButtons = buttons.length;

    if (activeTab === "All") {
      setFilteredImages(mediaImages);
      setTempImages(mediaImages.slice(0, ITEM_COUNT));
    } else {
      setFilteredImages([]);
      setTimeout(() => {
        const filteredImages = mediaImages.filter(
          (mediaImage) => mediaImage.category === activeTab
        );
        setFilteredImages(filteredImages);
        setTempImages(filteredImages.slice(0, ITEM_COUNT));
      }, 1);
    }
  };

 useEffect(() => {
    filterImages2();
  }, []);

  const filterImages = (e, category) => {
    e.preventDefault();
    CURRENT_PAGE = 0;
    const buttons = document.getElementsByClassName("btn-filter");
    const totalButtons = buttons.length;

    for (let i = 0; i < totalButtons; i++) {
      if (e.target.value === buttons[i].value) {
        buttons[i].classList.add("active");
      } else {
        buttons[i].classList.remove("active");
      }
    }
    

    if (category === strings["text_317"]) {
      setFilteredImages(mediaImages);
      setTempImages(mediaImages.slice(0, ITEM_COUNT));
    } else {
      setFilteredImages([]);
      setTimeout(() => {
        const filteredImages = mediaImages.filter(
          (mediaImage) => mediaImage.category === category ||  mediaImage.categoryTr === category
        );
        setFilteredImages(filteredImages);
        setTempImages(filteredImages.slice(0, ITEM_COUNT));
      }, 1);
    }
  };

  const changePage = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      CURRENT_PAGE = pageNumber;
      setTempImages(
        filteredImages.slice(
          pageNumber * ITEM_COUNT,
          pageNumber * ITEM_COUNT + ITEM_COUNT
        )
      );
    }
  };

  const btnFilters = [
    strings["text_317"],
    strings["text_318"],
    strings["text_319"],
    strings["text_320"],
    strings["text_321"],
    strings["text_322"],
    strings["text_323"]
  ].map((filter, i) => (
    <button
      key={i}
      value={filter}
      className={filter === activeTab ? "btn-filter active" : "btn-filter"}
      onClick={(e) => filterImages(e, filter)}
    >
      {filter}
    </button>
  ));

  const btnsPagination = [];
  for (let index = 0; index < totalPages; index++) {
    btnsPagination.push(
      <button
        key={index}
        onClick={() => changePage(index)}
        value={index}
        className={
          index === CURRENT_PAGE
            ? "pagination-button active"
            : "pagination-button"
        }
      >
        {index + 1}
      </button>
    );
  }

  const settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 3,
    },
    mode: "dark",
  };

  return (
    <div className="media">
      <Header />
      <div>
        <div id="gallery" className="gallery">
          <div className="row mx-2 mx-md-0 mt-5 mb-3">
            <div className="col-12 d-none d-md-block"></div>
            <h4 className="section-header text-md-center m-0 col-md-4 col-6 scroll-top">
              {strings["Gallery"]}
            </h4>

            <div className="btn-filters col-lg-12">{btnFilters}</div>
          </div>
          <div className="mx-2 mx-sm-0">
            {filteredImages.length > 0 ? (
              <Lightroom images={tempImages} settings={settings} />
            ) : (
              <div style={{ height: 500, width: "100%" }}></div>
            )}
          </div>
          <div className="d-flex justify-content-center mt-3 paginator-container">
            <button
              onClick={() => changePage(CURRENT_PAGE - 1)}
              className="pagination-button"
              disabled={CURRENT_PAGE === 0}
            >
              <BsCaretLeftFill />
            </button>
            {btnsPagination}
            <button
              onClick={() => changePage(CURRENT_PAGE + 1)}
              className="pagination-button"
              disabled={CURRENT_PAGE === totalPages - 1}
            >
              <BsCaretRightFill />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default connect()(multilanguage(Media));
