import React from "react";
import "./HeaderCard.css";

const HeaderCard = () => {
  return (
    <div className="header-card">
      <a href="/yilbasi_2025.pdf" target="_blank" rel="noopener noreferrer">
        <img
          src="/img/header_card.jpg"
          alt="New Year Gala"
          className="header-card-image"
        />
      </a>
    </div>
  );
};

export default HeaderCard;
