import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const OfferSection = ({ strings }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [count, setCount] = useState(2);
  const [countTwo, setCountTwo] = useState(0);

  const handleOpenDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const incrementCountTwo = () => {
    setCountTwo(countTwo + 1);
  };

  const decrementCountTwo = () => {
    if (countTwo > 0) {
      setCountTwo(countTwo - 1);
    }
  };

  function handleDateChange(date) {
    // initial change: start by setting the startDate
    if (!startDate && !endDate) {
      setStartDate(date);
      // startDate has been set, set the end date
    } else if (startDate && !endDate) {
      setEndDate(date);
    }

    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null);
    }
  }

  return (
    <section class="offer section">
      <div class="container d-md-flex align-items-center">
        <div class="offer_media" data-aos="fade-right">
          <img
            className="lazy home_book_now_img"
            data-src="img/gallery/book_now.webp.jpg"
            src="img/gallery/book_now.webp"
            alt="Every 5th breakfast as a gift."
          />
        </div>
        <div class="offer_main">
          <h6
            class="offer_main-subtitle icon mb-3"
            style={{ color: "black" }}
            data-aos="fade-up"
          >
            <p>{strings["book"]}</p>
          </h6>
          <button
            onClick={handleOpenDatePicker}
            className="btn book_button_offer"
          >
            <p>{strings["text_8"]}</p>
          </button>{" "}
          {isOpen && (
            <div className="date_picker-1">
              <DatePicker
                onChange={(date) => handleDateChange(date)}
                selectsStart={true}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                inline={true}
              >
                <div className="row">
                  <div className="col-lg-6 res_adult_book">
                    {" "}
                    <div className="math_section pt-3">
                      <h5 className=" pl-2 book_now_title_one">
                        {strings["text_103"]}
                      </h5>
                      <div className="d-flex gap-3">
                        {" "}
                        <button
                          className="btn btn-decr btn-sm"
                          onClick={decrementCount}
                        >
                          <h5>-</h5>
                        </button>
                        <h4 className="padding_3"> {count}</h4>
                        <button
                          className="btn btn-incr btn-sm"
                          onClick={incrementCount}
                        >
                          <h5>+</h5>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 res_child_book pl-3">
                    {" "}
                    <div className="math_section pt-3">
                      <h5 className="text-center">{strings["text_104"]}</h5>
                      <div className="d-flex gap-3">
                        {" "}
                        <button
                          className="btn btn-decr btn-sm"
                          onClick={decrementCountTwo}
                        >
                          <h5>-</h5>
                        </button>
                        <h4 className="padding_3"> {countTwo}</h4>
                        <button
                          className="btn btn-incr btn-sm"
                          onClick={incrementCountTwo}
                        >
                          <h5>+</h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center pt-3">
                  <button
                    onClick={() => {
                      console.log(startDate);
                      console.log(endDate);
                      const startYear =
                        startDate.getMonth() + "" + startDate.getFullYear();
                      const endYear =
                        endDate.getMonth() + "" + endDate.getFullYear();
                      const endDay = endDate.getDate();
                      const startDay = startDate.getDate();
                      const link =
                        "https://www.ihg.com/crowneplaza/hotels/tr/tr/find-hotels/select-roomrate?qDest=Macun%20Mah.%20171%20Cad.%20No:%202/1,%20Ankara,%20TR&qPt=CASH&qCiD=" +
                        startDay +
                        "&qCoD=" +
                        endDay +
                        "&qCiMy=" +
                        startYear +
                        "&qCoMy=" +
                        endYear +
                        "&qAdlt=" +
                        count +
                        "&qChld=" +
                        countTwo +
                        "&qRms=1&qRtP=6CBARC&qAAR=6CBARC&qSlH=ankcr&srb_u=1&qSrt=sBR&qBrs=6c.hi.ex.sb.ul.ic.cp.cw.in.vn.cv.rs.ki.ma.sp.va.sp.re.vx.nd.ii.sx.we.lx&qWch=0&qSmP=0&qRad=30&qRdU=mi&setPMCookies=false&qpMn=0&qRmFltr=";
                      window.open(link, "_blank");
                    }}
                    className="btn datepicker_btn btn-sm w-100"
                  >
                    <a href="" target="_blank">
                      {strings["text_8"]}
                    </a>
                  </button>
                </div>
              </DatePicker>
            </div>
          )}
        </div>
      </div>
      <div class="container d-md-flex align-items-center mt-3">
        {" "}
        <div class="offer_main">
          <h6
            class="offer_main-subtitle icon mb-3"
            style={{ color: "black" }}
            data-aos="fade-up"
          >
            <h5 style={{ fontWeight: "bold" }}> {strings["text_155"]}</h5>
            <p>{strings["text_156"]}</p>{" "}
          </h6>
          <button className="btn book_button_offer">
            <p>
              <a
                target="_blank"
                href="https://www.ihg.com/hotels/tr/tr/reservation"
              >
                {strings["discover"]}
              </a>
            </p>
          </button>
        </div>
        <div class="offer_media_two" data-aos="fade-right">
          <a
            target="_blank"
            href="https://www.ihg.com/hotels/tr/tr/reservation"
          >
            <img className="ihg_one_home" src="/img/ihg_one_rewards.png" />
          </a>
        </div>
      </div>
    </section>
  );
};
OfferSection.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(OfferSection));
