import React from "react";
import Header from "../../src/components/Header/Header";
import HeroSectionStart from "../../src/components/HeroSectionStart/HeroSectionStart";
import Banner from "../../src/components/Banner/Banner";
import OfferSection from "../../src/components/OfferSection/OfferSection";
import MenuSection from "../../src/components/MenuSection/MenuSection";
import BookingSection from "../../src/components/BookingSection/BookingSection";
import PresentationSection from "../../src/components/PresentationSection/PresentationSection";
import PresentationSectionTwo from "../../src/components/presentationSectionTwo/presentationSectionTwo";
import Map from "../../src/components/Map/Map";
import Footer from "../../src/components/Footer/Footer";
import BookingSectionTwo from "../components/BookingSectionTwo/BookinSectionTwo";
import BookingSectionThree from "../components/BookingSectionThree/BookingSectionThree";
import PresentationSectionThree from "../components/PresentationSectionThree/PresentationSectionThree";
import BookingSectionFour from "../components/BookingSectionFour/BookingSectionFour";
import PresentationSectionFour from "../components/PresentationSectionFour/PresentationSectionFour"
import VideoSection from "../components/VideoSection/VideoSection";
import HeaderTwo from "../components/HeaderTwo/HeaderTwo";

const Home = () => {
  return (
    <div>
  <HeaderTwo />   
      <VideoSection/> 
      <HeroSectionStart />  
      <Banner />
      <OfferSection />
      <BookingSection />
   
      <PresentationSection />
      <BookingSectionTwo />
      <PresentationSectionTwo />
      <BookingSectionThree />

      <PresentationSectionThree />
      <BookingSectionFour />

      <PresentationSectionFour />
      <MenuSection />

      <Map /> 
      <Footer />
    </div>
  );
};

export default Home;
