import React from "react";
import { Link } from "react-router-dom";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const BookingSectionThree = (props) => {
  return (
    <section class="booking section">
      <div class="container">
        <div class="section_header centered">
     
          <h3
            class="section_header-title"
            data-aos="fade-up"
            data-aos-delay="50"
          >
      {props.strings["text_279"]} 
          </h3>
          <p
            class="section_header-text"
            data-aos="fade-up"
            data-aos-delay="100"
          >
  {props.strings["text_273"]} </p>
        </div>
    
      </div>
    </section>
  );
};

BookingSectionThree.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(BookingSectionThree));
