import React from "react";
import gif from "./scroll_cropped.gif";
import HeaderCard from "../HeaderCard/HeaderCard"; // Ensure the path is correct
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const VideoSection = (props) => {
  return (
    <section>
      <div className="video_section_inner">
        <div className="video-container">
          <video className="meeting_video_large" autoPlay={true} loop muted>
            <source src="/video/crowne_slider_dusuk_rev.mp4" type="video/mp4" />
          </video>
          <video className="meeting_video_small" autoPlay={true} loop muted>
            <source src="/video/crowne_slider_dik_son.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="overlay_two"></div>

        <div className="overlay overlay_home">
          <img
            className="header_two_logo_video"
            src="img/logo/crowne_logo.svg"
            alt="Logo"
          />
          <br />
          <h3 className="video_text">{props.strings["slogan"]}</h3>
          <div className="d-flex justify-content-center">
            <img style={{ width: "50%" }} src={gif} alt="Scroll GIF" />
          </div>
          <div className="d-flex justify-content-center align-items-end">
            <h6 className="home_tel_link">
              <a className="white_link" href="tel:+03123200000">
                +90 312 320 00 00
              </a>
            </h6>
          </div>
        </div>
      </div>

      {/* HeaderCard added here */}
      <HeaderCard url="https://example.com" text="Click Me!" />
    </section>
  );
};

VideoSection.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(VideoSection));
