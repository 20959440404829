import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {
  multilanguage,
  loadLanguages,
  changeLanguage,
} from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/dist";
import ReactHtmlParser from "react-html-parser";
const RestaurantBar = (props) => {
  return (
    <div className="restaurant">
      <Header />

      <section class="hero hero_dining">
        <div class="container">
          <div class="hero_main">
            <div class="section_header">
              <img className="auro_logo" src="/img/aura_logo.svg" />
              <p className="aura_text ">{props.strings["text_125"]}</p>
              <div className="restaurant_date">
                <h6>
                  <strong> {props.strings["text_291"]}</strong> 06.30 - 10.30
                </h6>
                <h6>
                  <strong>{props.strings["text_292"]}</strong> 06.30 - 11.00
                </h6>
                <h6>
                  <strong>{props.strings["text_293"]}</strong> 12.00 - 22.30
                </h6>
              </div>
              <div class="wrapper d-flex restaurant_button_span">
                <span data-aos="fade-up">
                  <a
                    class="btn "
                    href="https://qrmenu.crowneplazaankarahotel.com/aura"
                    target="_blank"
                    className="qr_menu btn"
                  >
                    {props.strings["text_118"]}
                  </a>
                </span>
                <span data-aos="fade-up">
                  <Link
                    class="btn "
                    to={{ pathname: "/gallery", search: "?tab=Dining" }}
                    className="auro_gallery btn"
                  >
                    {props.strings["text_6"]}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation]}
            loop={true}
            className="hero_slider swiper"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            <SwiperSlide>
              {" "}
              <picture>
                <source
                  data-srcset="/img/aura_01.webp"
                  srcSet="/img/aura_01.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/aura_01.webp"
                  src="/img/aura_01.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/aura_02.webp"
                  srcSet="/img/aura_02.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/aura_02.webp"
                  src="/img/aura_img_02.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/aura_03.webp"
                  srcSet="/img/aura_03.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/aura_img_03.webp"
                  src="/img/aura_03.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>

            <div className="hero_slider-nav swiper-overlay-nav swiper-nav">
              <a className="swiper-button-prev nav-item">
                <i className="icon-chevron-left" />
              </a>
              <a className="swiper-button-next nav-item" href="#">
                <i className="icon-chevron-right" />
              </a>
            </div>
          </Swiper>
        </div>
      </section>
      <section className="hero hero_dining">
        <div class="container ">
          <div class="hero_main">
            <div class="section_header">
              <img className="auro_logo" src="/img/one_bar_logo.svg" />
              <p className="aura_text "> {props.strings["text_126"]}</p>
              <div className="restaurant_date">
                <h6>
                  <strong>{props.strings["text_294"]}</strong> 08.00 - 01.00
                </h6>
              </div>
              <div class="wrapper d-flex restaurant_button_span">
                <span data-aos="fade-up">
                  <a
                    class="btn "
                    href="https://qrmenu.crowneplazaankarahotel.com/lobby"
                    target="_blank"
                    className="qr_menu btn"
                  >
                    {props.strings["text_118"]}
                  </a>
                </span>
                <span data-aos="fade-up">
                  <Link
                    class="btn "
                    to={{ pathname: "/gallery", search: "?tab=Dining" }}
                    className="auro_gallery btn"
                  >
                    {props.strings["text_6"]}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation]}
            loop={true}
            className="hero_slider swiper"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            <SwiperSlide>
              {" "}
              <picture>
                <source
                  data-srcset="/img/one_bar_01.webp"
                  srcSet="/img/one_bar_01.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/one_bar_01.webp"
                  src="/img/one_bar_01.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/one_bar_02.webp"
                  srcSet="/img/one_bar_02.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/one_bar_02.webp"
                  src="/img/one_bar_02.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/one_bar_03.webp"
                  srcSet="/img/one_bar_03.webp"
                  type="image/webp"
                />
                <img
                  className="lazy"
                  data-src="/img/one_bar_03.webp"
                  src="/img/one_bar_03.webp"
                  alt="media"
                />
              </picture>
            </SwiperSlide>

            <div className="hero_slider-nav swiper-overlay-nav swiper-nav">
              <a className="swiper-button-prev nav-item">
                <i className="icon-chevron-left" />
              </a>
              <a className="swiper-button-next nav-item" href="#">
                <i className="icon-chevron-right" />
              </a>
            </div>
          </Swiper>
        </div>
      </section>
      <section className="hero hero_dining">
        <div class="container ">
          <div class="hero_main hero_main_premium">
            <div class="section_header">
              <h1>Premium Lounge</h1>
              <p className="aura_text "> {props.strings["text_290"]}</p>
              <div className="restaurant_date_premium">
                <h6>
                  <strong>{props.strings["text_294"]}</strong> 06.30 - 22.30
                </h6>
                <h6 className="text-start">
                  <strong>{props.strings["text_291"]}</strong> 06.30 - 10.30
                </h6>
              </div>
              <div className="restaurant_date_premium">
                <h6>
                  <strong>{props.strings["text_295"]}</strong> 10.30 - 22.00
                </h6>
                <h6 className="text-right">
                  <strong>Happy Hour:</strong> 12.00 - 22.30
                </h6>
              </div>
              <div class="wrapper d-flex restaurant_button_span">
                <span data-aos="fade-up">
                  <a
                    class="btn "
                    href="https://qrmenu.crowneplazaankarahotel.com/lobby"
                    target="_blank"
                    className="qr_menu btn"
                  >
                    {props.strings["text_118"]}
                  </a>
                </span>
                <span data-aos="fade-up">
                  <Link
                    class="btn "
                    to={{ pathname: "/gallery", search: "?tab=Dining" }}
                    className="auro_gallery btn"
                  >
                    {props.strings["text_6"]}
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation]}
            loop={true}
            className="hero_slider swiper"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            <SwiperSlide>
              {" "}
              <picture>
                <source
                  data-srcset="/img/lounge_01.png"
                  srcSet="/img/lounge_01.png"
                  type="image/png"
                />
                <img
                  className="lazy"
                  data-src="/img/lounge_01.png"
                  src="/img/lounge_01.png"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/lounge_02.png"
                  srcSet="/img/lounge_02.png"
                  type="image/png"
                />
                <img
                  className="lazy"
                  data-src="/img/lounge_02.png"
                  src="/img/lounge_02.png"
                  alt="media"
                />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              <picture>
                <source
                  data-srcset="/img/lounge_03.png"
                  srcSet="/img/lounge_03.png"
                  type="image/png"
                />
                <img
                  className="lazy"
                  data-src="/img/lounge_03.png"
                  src="/img/lounge_03.png"
                  alt="media"
                />
              </picture>
            </SwiperSlide>

            <div className="hero_slider-nav swiper-overlay-nav swiper-nav">
              <a className="swiper-button-prev nav-item">
                <i className="icon-chevron-left" />
              </a>
              <a className="swiper-button-next nav-item" href="#">
                <i className="icon-chevron-right" />
              </a>
            </div>
          </Swiper>
        </div>
      </section>
      <Footer />
    </div>
  );
};

RestaurantBar.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(RestaurantBar));
