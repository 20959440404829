import React from "react";

const Map = () => {
  return (
    <section style={{ height: "500px" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3058.756282869242!2d32.765629075203016!3d39.94684088410581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349e1985b0a69%3A0xf8ef01414a0f037d!2sCrowne%20Plaza%20Ankara!5e0!3m2!1str!2str!4v1687261385155!5m2!1str!2str"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

export default Map;
