import logo from "./logo.svg";
import "./App.css";
import Home from "./routes/Home";
import Rooms from "./routes/Rooms";
import About from "./routes/About";
import SpaFitness from "./routes/SpaFitness";
import { Route, Routes } from "react-router-dom";
import MeetingEvents from "./routes/MeetingEvents";
import Contact from "./routes/Contact";
import Gallery from "./routes/Gallery";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import RestaurantBar from "./routes/RestaurantBar";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import NotFound from "./components/NotFound"; 

function App(props) {
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  const handleOrientationChange = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
  }, []);

  props.dispatch(
    loadLanguages({
      languages: {
        en: require("./data/Translation/english.json"),
        tr: require("./data/Translation/turkish.json"),
      },
    })
  );
  return (
    <>
    {/*   {!isPortrait && (
        <div className="landscape-overlay">
          {" "}
          <img className="rotate_img" src="/img/rotate.png"></img>
          <p className="text-white">{props.strings["text_278"]}</p>
        </div>
      )} */}
      <ScrollToTop>
        <Routes basename="/">
          <Route  path="/" element={<Home />}></Route>
          <Route  path="/rooms" element={<Rooms />}></Route>
          <Route   path="/spa_fitness" element={<SpaFitness />}></Route>
          <Route  path="/restaurant" element={<RestaurantBar />}></Route>
          <Route  path="/gallery" element={<Gallery />}></Route>
          <Route   path="/meeting_events" element={<MeetingEvents />}></Route>
          <Route  path="/contact" element={<Contact />}></Route>
          <Route  path="/about" element={<About />}></Route>
          <Route path="/*" element={<NotFound />}></Route> 
        </Routes>
      </ScrollToTop>
    </>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(App));
