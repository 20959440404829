import React from "react";
import "../../assets/css/MeetingEvents/MeetingEvents.css";

const TableTwo = () => {
  return (
    <div className="meeting_table">
      <table class="table table-hover">
        <thead>
          <tr className="tr_header">
            <th scope="col">Meeting Space</th>
            <th scope="col">Length(m)</th>
            <th scope="col">Width (m)</th>
            <th scope="col">Height(m)</th>
            <th scope="col">m²</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Angora Ballroom</th>
            <td>31,5</td>
            <td>24</td>
            <td>5,4</td>
            <td>760</td>
          </tr>
          <tr>
            <th scope="row">Angora Ballroom 1</th>
            <td>31,5</td>
            <td>12</td>
            <td>5,4</td>
            <td>380</td>
          </tr>
          <tr>
            <th scope="row">Angora Ballroom 2</th>
            <td>31,5</td>
            <td>12</td>
            <td>5,4</td>
            <td>380</td>
          </tr>
          <tr>
            <th scope="row">Ankuva Ballroom</th>
            <td>17,4</td>
            <td>23</td>
            <td>6,4</td>
            <td>400</td>
          </tr>
          <tr>
            <th scope="row">Ankuva Ballroom 1</th>
            <td>17,4</td>
            <td>11,5</td>
            <td>6,4</td>
            <td>200</td>
          </tr>
          <tr>
            <th scope="row">Ankuva Ballroom 2</th>
            <td>17,4</td>
            <td>11,5</td>
            <td>6,4</td>
            <td>200</td>
          </tr>

          <tr>
            <th scope="row">Ankyra Meeting Room</th>
            <td>8,4</td>
            <td>8,4</td>
            <td>5</td>
            <td>70</td>
          </tr>
          <tr>
            <th scope="row">Convention Terrace</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>115</td>
          </tr>

          <tr>
            <th scope="row">Convention Foyer</th>
            <td>47,5</td>
            <td>7,7</td>
            <td>5</td>
            <td>366</td>
          </tr>
          <tr>
            <th scope="row">Aura Restaurant</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>420</td>
          </tr>
          <tr>
            <th scope="row">Aura PDR</th>
            <td>6,4</td>
            <td>3,5</td>
            <td>-</td>
            <td>22</td>
          </tr>

          <tr>
            <th scope="row">Nora Meeting Room</th>
            <td>6,95</td>
            <td>7,85</td>
            <td>3,2</td>
            <td>55</td>
          </tr>
          <tr>
            <th scope="row">Nora VIP Room</th>
            <td>5,91</td>
            <td>3,84</td>
            <td>-</td>
            <td>23</td>
          </tr>
          <tr>
            <th scope="row">Vera Meeting Room</th>
            <td>6,75</td>
            <td>7,36</td>
            <td>3,2</td>
            <td>50</td>
          </tr>
          <tr>
            <th scope="row">Mira Meeting Room</th>
            <td>6,75</td>
            <td>7,36</td>
            <td>3,2</td>
            <td>50</td>
          </tr>
          <tr>
            <th scope="row">Mezzanine Foyer</th>
            <td>22,5</td>
            <td>5,2</td>
            <td>3,5</td>
            <td>120</td>
          </tr>
          <tr>
            <th scope="row">Terra Meeting Room</th>
            <td>13,3</td>
            <td>16</td>
            <td>3</td>
            <td>215</td>
          </tr>
          <tr>
            <th scope="row">Terra Meeting Room 1</th>
            <td>13,3</td>
            <td>8,5</td>
            <td>3</td>
            <td>115</td>
          </tr>
          <tr>
            <th scope="row">Terra Meeting Room 2</th>
            <td>13,3</td>
            <td>7,5</td>
            <td>3</td>
            <td>100</td>
          </tr>
          <tr>
            <th scope="row">Terra Foyer</th>
            <td>16</td>
            <td>8,6</td>
            <td>3</td>
            <td>138</td>
          </tr>
          <tr>
            <th scope="row">Terra Terrace</th>
            <td>7</td>
            <td>3,5</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <th scope="row">Hera Meeting Room</th>
            <td>19,5</td>
            <td>6,85</td>
            <td>3,5</td>
            <td>135</td>
          </tr>

          <tr>
            <th scope="row">Dora Meeting Room</th>
            <td>12,75</td>
            <td>11,9</td>
            <td>3,5</td>
            <td>152</td>
          </tr>
          <tr></tr>
          <tr>
            <th scope="row">Premium Lounge</th>
            <td>7,25</td>
            <td>5,5</td>
            <td>-</td>
            <td>25</td>
          </tr>

          <tr>
            <th scope="row">Premium Lounge Terrace</th>
            <td>5,75</td>
            <td>2,2</td>
            <td>-</td>
            <td>40</td>
          </tr>
          <tr>
            <th scope="row">4th Floor Terrace</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>

            <td>1110 m²</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableTwo;
